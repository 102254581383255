<template>
    <div class="md-overflow-y-scroll md-max-height-20">
        <div
            v-if="!loading && protocolEntries.length === 0"
        >
            <li class="list-group-item list-group-item-no-vertical-border text-center w-100">
                <em class="text-muted" v-html="this.noEntriesYetPlaceholder"></em>
            </li>
        </div>
        <div
            v-if="loading"
        >
            <li class="list-group-item list-group-item-no-vertical-border text-center w-100">
                <em class="text-muted">Wird geladen …</em>
            </li>
        </div>
        <school-exchange-contact-protocol-entry-component
                v-else
                v-for="protocolEntry in protocolEntries"
                :key="protocolEntry.id"
                :protocolEntry="protocolEntry"
                :destroy-protocol-entry-api-url="destroyProtocolEntryApiUrl"
        >
        </school-exchange-contact-protocol-entry-component>
    </div>
</template>

<script>

    import Event from '../event.js';
    import applyConverters from 'axios-case-converter';

    import SchoolExchangeContactProtocolEntryComponent from "./SchoolExchangeContactProtocolEntryComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            noEntriesYetPlaceholder: String,
            destroyProtocolEntryApiUrl: String,
        },
        data() {
            return {
                loading: true,
                axiosClient: null,
                protocolEntries: [],
            }
        },
        mounted() {
            this.initializeAxiosClient();
            this.loadProtocolEntries();
            Event.$on('added_contact_protocol_entry', (protocolEntry) => {
                this.protocolEntries.unshift(protocolEntry);
            });
            Event.$on('removed_contact_protocol_entry', (protocolEntry) => {
                this.protocolEntries.splice(this.protocolEntries.findIndex((existingProtocolEntry) => {
                    return existingProtocolEntry.id === protocolEntry.id;
                }), 1);
            });
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            loadProtocolEntries: function() {
                this.axiosClient.get(`/api/v1/schoolExchange/${this.schoolExchangeId}/contactProtocolEntry`).then(response => {
                    this.protocolEntries = response.data;
                    this.loading = false;
                });
            },
        },
        name: "school-exchange-contact-protocol-entries-component",
        components: {
            SchoolExchangeContactProtocolEntryComponent,
        },
    }

</script>

<style scoped>

</style>
