<template>
    <li class="list-group-item list-group-item-no-vertical-border">
        <div class="d-flex w-100">
            <div class="mr-3" >
                <div class="chat-user-image" style="background-image: url('https://via.placeholder.com/150');"></div>
            </div>
            <div class="w-100">
                <div class="d-flex flex-wrap w-100 justify-content-between">
                    <h5 class="mb-1 mr-2 flex-grow-1">{{ schoolExchangeChatMessage.student.name }}</h5>
                    <dynamic-from-now-component v-b-tooltip.hover :title="schoolExchangeChatMessage.created_at | dayjs"
                                                :value="schoolExchangeChatMessage.created_at" :interval="2000" tag="small"
                                                class="flex-shrink-0"></dynamic-from-now-component>
                </div>
                <p class="mb-1 white-space-pre-line">{{ schoolExchangeChatMessage.body }}</p>
            </div>
        </div>
    </li>
</template>

<script>

    import dayjs from 'dayjs';
    import 'dayjs/locale/de';
    import DynamicFromNowComponent from "./DynamicFromNowComponent";

    export default {
        props: [
            'schoolExchangeChatMessage'
        ],
        components: {
            DynamicFromNowComponent
        },
        filters: {
            // TODO Use "ago" here instead of the fixed format
            dayjs: function(args) {
                return dayjs(args).locale('de').format("DD.MM.YYYY HH:mm:ss");
            }
        },
        name: "school-exchange-chat-message-component",
    }

</script>

<style scoped>

</style>
