<template>
    <li class="list-group-item list-group-item-no-vertical-border">
        <div class="mt-1 mb-1 d-flex w-100 justify-content-between align-items-baseline flex-wrap">
            <span class="flex-grow-1"><strong>{{ protocolEntry.student.name }}</strong> ({{ protocolEntry.student.groupMembers[0].group.name }})</span>
            <div class="flex-shrink-0 ml-auto">
                <b-button :disabled="isDeleting" @click="confirmDelete" v-b-tooltip.hover title="Eintrag löschen" variant="link" v-if="protocolEntry.isCreatedByCurrentUser">
                    <i class="fas fa-trash-alt fa-fw text-danger"></i>
                </b-button>
                <dynamic-from-now-component v-b-tooltip.hover :title="protocolEntry.createdAt | dayjs"
                                            :value="protocolEntry.createdAt" :interval="2000" tag="small"
                                            class="text-muted"></dynamic-from-now-component>
            </div>
        </div>
        <h5 class="mb-1">{{ protocolEntry.title }}</h5>
        <p v-if="!!protocolEntry.body" class="mb-1 white-space-pre-line">{{ protocolEntry.body }}</p>
    </li>
</template>

<script>

    import Event from '../event.js';
    import applyConverters from 'axios-case-converter';

    import dayjs from 'dayjs';
    import 'dayjs/locale/de';

    import DynamicFromNowComponent from "./DynamicFromNowComponent";

    export default {
        components: {
            DynamicFromNowComponent
        },
        data() {
            return {
                axiosClient: null,
                isDeleting: false,
            }
        },
        props: {
            protocolEntry: Object,
            destroyProtocolEntryApiUrl: String,
        },
        filters: {
            dayjs: function (args) {
                return dayjs(args).locale('de').format("DD.MM.YYYY HH:mm:ss");
            }
        },
        mounted() {
            this.initializeAxiosClient();
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            confirmDelete: async function(event) {
                event.preventDefault();
                try {
                    const confirmation = await this.$bvModal.msgBoxConfirm('Bist du sicher, dass du den Eintrag löschen möchtest?', {
                        title: 'Kontaktprotokoll-Eintrag löschen',
                        size: 'md',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'Ja, löschen',
                        cancelTitle: 'Nein, abbrechen',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    });
                    if (!!confirmation && confirmation) {
                        this.isDeleting = true;
                        try {
                            await this.axiosClient.delete(`${this.destroyProtocolEntryApiUrl}/${this.protocolEntry.id}`);
                            Event.$emit('removed_contact_protocol_entry', this.protocolEntry);
                        } catch (error) {
                            console.error(error);
                        } finally {
                            this.isDeleting = false;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        },
        name: "school-exchange-contact-protocol-entry-component",
    }

</script>

<style scoped>

</style>
