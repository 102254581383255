<template>

    <b-popover
        variant="light"
        :show="show"
        triggers="manual"
        :target="target"
        :title="title"
        placement="topright"
    >
        <template v-slot:title>
            <div class="d-flex align-items-baseline">
                <span>
                    {{ title }}
                </span>
                <b-button-close class="ml-auto" @click="show = false"></b-button-close>
            </div>
        </template>
        {{ content }}
    </b-popover>

</template>

<script>

    export default {
        props: {
            amount: {
                type: Number,
                required: true,
            },
            target: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                show: true,
                isHiding: false,
            }
        },
        computed: {
            membersPluralised: function () {
                return (this.amount > 1) ? "neue Mitglieder" : "neues Mitglied";
            },
            membersJoinedPluralised: function () {
                const prefix = (this.amount > 1) ? "sind" : "ist";
                return `${prefix} ${this.amount} ${this.membersPluralised} beigetreten`;
            },
            title: function () {
                return `🎉 ${this.amount} ${this.membersPluralised}!`;
            },
            content: function () {
                return `Dieser Schul-Koordinationsgruppe ${this.membersJoinedPluralised}.`;
            },
        },
        mounted() {
            this.$parent.$refs.groupMembersTooltipLink.addEventListener('mouseover', this.hide);
        },
        beforeDestroy() {
            this.$parent.$refs.groupMembersTooltipLink.removeEventListener('mouseover', this.hide);
        },
        methods: {
            hide: function() {
                this.show = false;
            },
        },
        name: "newly-joined-students-info-component",
    }

</script>
