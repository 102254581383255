<template>
    <div>
        <b-button
                variant="primary"
                @click="modalShow = !modalShow"
        >
            Schule suchen/hinzufügen
        </b-button>

        <!-- Modal Component -->
        <b-modal
                id="modal-select-or-create-schoolexchange"
                v-model="modalShow"
                size="lg"
                scrollable
                modal-class="modal-fullscreen-bottom-footer"
                title="Schule suchen/hinzufügen"
        >
            <b-form
                    @submit.prevent="onSubmit"
            >

                <b-form-group
                        id="searchExistingSchoolInputGroup"
                        label="Existierende Schule suchen:"
                        label-for="searchExistingSchoolInput"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                >
                    <vue-bootstrap-typeahead
                            id="searchExistingSchoolInput"
                            placeholder="Name, Adresse oder Stadt der Schule"
                            v-model="schoolSearch"
                            :data="schoolsIncludingNewSchoolEntry"
                            :serializer="serializeSchool"
                            @hit="schoolNameTypeaheadHit"
                            :raw-results="true"
                            :disabled="isSubmitting"
                    >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span v-if="data.id === -1">
                                <strong>{{ data.name }}</strong><br/>
                                <small>Neue Schule anlegen</small>
                            </span>
                            <span v-else>
                                <div v-if="!!data.amountOfSchoolExchangeStudents" class="d-block">
                                    <span class="badge badge-info rounded shadow-sm mb-2 ">
                                        <span class="badge badge-icon">
                                            <i class="fas fa-suitcase"></i>
                                        </span>
                                        Schul-Koordinationsgruppe mit {{ data.amountOfSchoolExchangeStudents }} Mitglied{{ data.amountOfSchoolExchangeStudents > 1 ? 'ern' : '' }} gefunden!
                                    </span>
                                </div>
                                <span v-html="htmlText"></span>
                                <span class="d-block text-muted small">{{ data.address ? `${data.address} ` : '' }}{{ data.state ? `(${data.state})` : '' }}</span>
                                <span v-if="data.schoolType" class="d-block text-muted small"><em>{{ data.schoolType }}</em></span>
                            </span>
                        </template>
                    </vue-bootstrap-typeahead>
                </b-form-group>

                <b-card
                        bg-variant="light"
                        v-if="isAddingNewSchool"
                >
                    <b-form-group
                            id="newSchoolNameInputGroup"
                            label-cols-sm="3"
                            label="Name:*"
                            label-align-sm="right"
                            label-for="newSchoolNameInput"
                    >
                        <b-form-input
                                id="newSchoolNameInput"
                                type="text"
                                required
                                :disabled="!isAddingNewSchool || isSubmitting"
                                v-model="newSchool.name"
                        />
                    </b-form-group>

                    <b-form-group
                            id="newSchoolAddressInputGroup"
                            label-cols-sm="3"
                            label="Adresse*:"
                            label-align-sm="right"
                            label-for="newSchoolAddressInput"
                    >
                        <b-form-input
                                id="newSchoolAddressInput"
                                type="text"
                                required
                                placeholder="bspw. Hüttenweg 12, 10491 Musterstadt"
                                :disabled="!isAddingNewSchool || isSubmitting"
                                v-model="newSchool.address"
                        />
                    </b-form-group>

                    <b-form-group
                        id="newSchoolStateInputGroup"
                        label-cols-sm="3"
                        label="Bundesland:"
                        label-align-sm="right"
                        label-for="newSchoolStateInput"
                    >
                        <vue-bootstrap-typeahead
                            id="newSchoolStateInput"
                            placeholder="bspw. Berlin"
                            v-model="stateSearch"
                            :data="statesIncludingNewEntry"
                            :serializer="item => `${item.name}`"
                            @hit="stateTypeaheadHit"
                            :min-matching-chars="1"
                            :disabled="!isAddingNewSchool || isSubmitting"
                        >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span v-if="data.id === -1">
                                <strong>{{ data.name }}</strong><br/>
                                <small>Neues Bundesland anlegen</small>
                            </span>
                                <span v-else v-html="htmlText"></span>
                            </template>
                        </vue-bootstrap-typeahead>
                    </b-form-group>

                    <b-form-group
                            id="newSchoolTypeEntityInputGroup"
                            label-cols-sm="3"
                            label="Schultyp:"
                            label-align-sm="right"
                            label-for="newSchoolTypeEntityInput"
                    >
                        <vue-bootstrap-typeahead
                                id="newSchoolTypeEntityInput"
                                placeholder="bspw. Grundschule"
                                v-model="schoolTypeEntitySearch"
                                :data="schoolTypeEntitiesIncludingNewEntry"
                                :serializer="item => `${item.name}`"
                                @hit="schoolTypeEntityTypeaheadHit"
                                :min-matching-chars="1"
                                :disabled="!isAddingNewSchool || isSubmitting"
                        >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span v-if="data.id === -1">
                                <strong>{{ data.name }}</strong><br/>
                                <small>Neuen Schul-Typ anlegen</small>
                            </span>
                                <span v-else v-html="htmlText"></span>
                            </template>
                        </vue-bootstrap-typeahead>
                    </b-form-group>

                    <b-form-group
                        id="newSchoolCustomSchoolTypeInputGroup"
                        label-cols-sm="3"
                        label="Schulart:"
                        label-align-sm="right"
                        label-for="newSchoolCustomSchoolTypeInput"
                    >
                        <b-form-input
                            id="newSchoolCustomSchoolTypeInput"
                            type="text"
                            placeholder="bspw. Oberschule mit Grundschule"
                            :disabled="!isAddingNewSchool || isSubmitting"
                            v-model="newSchool.customSchoolType"
                            aria-describedby="newSchoolCustomSchoolTypeInputHelp"
                        />
                        <b-form-text id="newSchoolCustomSchoolTypeInputHelp">Hiermit kann der Schultyp verfeinert werden.</b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="newSchoolFullTimeSchoolInputGroup"
                        label-cols-sm="3"
                        label=""
                        label-align-sm="right"
                        label-for="newSchoolFullTimeSchoolInputGroup"
                    >
                        <b-form-checkbox
                            id="newSchoolFullTimeSchoolInputGroup"
                            v-model="newSchool.fullTimeSchool"
                            name="newSchoolFullTimeSchoolInputGroup"
                            :disabled="!isAddingNewSchool || isSubmitting"
                            plain
                        >
                            Ganztagsschule
                        </b-form-checkbox>
                    </b-form-group>
                </b-card>
            </b-form>

            <div slot="modal-footer">
                <b-button
                        variant="secondary"
                        @click="modalShow = false"
                        :disabled="isSubmitting"
                >
                    Abbrechen
                </b-button>
                <b-button
                        variant="primary"
                        @click="onSubmit"
                        :disabled="!formIsCompleted || isSubmitting"
                >
                    {{ this.isSubmitting ? 'Bitte warten …' : (this.isAddingNewSchool ? 'Schule und Schul-Koordinationsgruppe anlegen' : 'Schul-Koordinationsgruppe beitreten') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import applyConverters from 'axios-case-converter';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    const _ = require("lodash");
    import applyOverridesAndFillEmptyValues from '../utilities/SchoolUtilities';

    export default {
        props: {
            schoolSearchApiUrl: String,
            schoolTypeEntitiesApiUrl: String,
            statesApiUrl: String,
            schoolExchangesApiUrl: String,
        },
        data: function () {
            return {
                axiosClient: null,
                schoolTypeEntities: [],
                states: [],
                schoolExchanges: [],
                modalShow: false,
                schoolSearch: '',
                schoolTypeEntitySearch: '',
                stateSearch: '',
                selectedSchoolId: null,
                newSchool: {},
                validationErrors: [],
                searchIsRunning: true,
                isSubmitting: false,
            }
        },
        computed: {
            schoolSearchWords() {
                return this.schoolSearch.split(' ');
            },
            state() {
                return (this.schoolSearchWords.length > 0 && this.schoolSearchWords[0].length >= 2);
            },
            invalidFeedback() {
                if (this.schoolSearch === "") {
                    return "";
                } else {
                    return "Bitte gib mindestens 2 Zeichen für die Suche ein."
                }
            },
            schoolsIncludingNewSchoolEntry: function () {
                if (this.searchIsRunning) {
                    return [
                    ]
                }
                if (this.selectedSchoolId && this.selectedSchoolId !== -1 && this.schools.find(school => school.id === this.selectedSchoolId) && this.schoolSearch === this.serializeSchool(this.schools.find(school => school.id === this.selectedSchoolId))) {
                    return this.schools;
                }
                return [...this.schools, {
                    id: -1,
                    name: this.schoolSearch,
                }];
            },
            schoolTypeEntitiesIncludingNewEntry: function () {
                if (this.schoolTypeEntities.some(schoolType => {
                    return schoolType.name === this.schoolTypeEntitySearch.trim()
                })) {
                    return this.schoolTypeEntities;
                }
                return [...this.schoolTypeEntities, {
                    id: -1,
                    name: this.schoolTypeEntitySearch,
                }];
            },
            statesIncludingNewEntry: function () {
                if (this.states.some(state => {
                    return state.name === this.stateSearch.trim()
                })) {
                    return this.states;
                }
                return [...this.states, {
                    id: -1,
                    name: this.stateSearch,
                }];
            },
            isAddingNewSchool: function () {
                return this.selectedSchoolId === -1;
            },
            isAddingNewTypeEntity: function () {
                return this.newSchool.schoolTypeEntity && this.newSchool.schoolTypeEntity.id === -1;
            },
            isAddingNewState: function () {
                return this.newSchool.state && this.newSchool.state.id === -1;
            },
            formIsCompleted: function () {
                if (this.isAddingNewSchool) {
                    return (this.newSchool && this.newSchool.name !== undefined && this.newSchool.address !== undefined);
                } else {
                    return !!this.selectedSchoolId;
                }
            }
        },
        watch: {
            schoolSearch() {
                this.searchIsRunning = true;
                this.reloadSchools();
            }
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            loadSchoolTypeEntities: function () {
                this.axiosClient.get(this.schoolTypeEntitiesApiUrl)
                    .then(response => {
                        this.schoolTypeEntities = response.data;
                    });
            },
            loadStates: function () {
                this.axiosClient.get(this.statesApiUrl)
                    .then(response => {
                        this.states = response.data;
                    });
            },
            loadSchoolExchanges: function () {
                this.axiosClient.get(this.schoolExchangesApiUrl)
                    .then(response => {
                        this.schoolExchanges = response.data;
                    });
            },
            serializeSchool: function(item) {
                return item.name;
            },
            schoolNameTypeaheadHit: function (data) {
                const id = data.id;
                if (id === -1) {
                    this.newSchool.name = data.name;
                } else {
                    this.newSchool = {};
                }
                this.selectedSchoolId = id;
            },
            schoolTypeEntityTypeaheadHit: function (data) {
                this.$set(this.newSchool, 'schoolTypeEntity', {
                    id: data.id,
                    name: data.name,
                });
            },
            stateTypeaheadHit: function (data) {
                this.$set(this.newSchool, 'state', {
                    id: data.id,
                    name: data.name,
                });
            },
            onSubmit: async function (event) {
                event.preventDefault();

                if (!(this.formIsCompleted)) {
                    return;
                }

                this.isSubmitting = true;

                let schoolId;
                let schoolExchangeId;

                if (this.isAddingNewSchool) {

                    let schoolTypeEntityId = undefined;

                    if (this.isAddingNewTypeEntity) {
                        try {
                            const response = await this.axiosClient.post('/api/v1/schoolTypeEntity', {
                                name: this.newSchool.schoolTypeEntity.name,
                            });
                            schoolTypeEntityId = response.data.id;
                        } catch (error) {
                            if (error.response.status === 422) {
                                this.validationErrors = error.response.data.errors;
                            }
                            this.isSubmitting = false;
                            return;
                        }

                    } else if (this.newSchool.schoolTypeEntity && this.newSchool.schoolTypeEntity.id) {
                        schoolTypeEntityId = this.newSchool.schoolTypeEntity.id;
                    }

                    let stateId = undefined;

                    if (this.isAddingNewState) {
                        try {
                            const response = await this.axiosClient.post('/api/v1/state', {
                                name: this.newSchool.state.name,
                            });
                            stateId = response.data.id;
                        } catch (error) {
                            if (error.response.status === 422) {
                                this.validationErrors = error.response.data.errors;
                            }
                            this.isSubmitting = false;
                            return;
                        }

                    } else if (this.newSchool.state && this.newSchool.state.id) {
                        stateId = this.newSchool.state.id;
                    }

                    try {
                        const response = await this.axiosClient.post('/api/v1/school', {
                            name: this.newSchool.name,
                            address: this.newSchool.address,
                            stateId: stateId,
                            schoolTypeEntityId: schoolTypeEntityId,
                            customSchoolType: this.newSchool.customSchoolType,
                            fullTimeSchool: this.newSchool.fullTimeSchool,
                        });
                        schoolId = response.data.id;
                    } catch (error) {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                        this.isSubmitting = false;
                        return;
                    }

                } else {
                    schoolId = this.selectedSchoolId;
                }

                try {
                    const response = await this.axiosClient.post('/api/v1/schoolExchange/join', {
                        schoolId: schoolId
                    });
                    schoolExchangeId = response.data.id;
                } catch (error) {
                    if (error.response.status === 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.isSubmitting = false;
                    return;
                }

                this.isSubmitting = false;
                window.location.href = `/schul-koordinationsgruppe/${schoolExchangeId}`;
            }
        },
        name: "create-school-exchange-component",
        components: {
            VueBootstrapTypeahead,
        },
        mounted: function () {
            this.initializeAxiosClient();
            this.loadSchoolTypeEntities();
            this.loadStates();
            this.loadSchoolExchanges();
        },
        created() {
            this.reloadSchools = _.debounce(() => {
                if (this.state) {
                    this.axiosClient.get(`${this.schoolSearchApiUrl}?q=${this.schoolSearchWords.join('%20')}`)
                        .then(response => {
                            let schoolBackup = undefined;
                            if (this.schools && this.schools.length > 0) {
                                schoolBackup = this.schools.find(school => school.id === this.selectedSchoolId);
                            }
                            // Move schools that have a school exchange to the top of the list
                            this.schools = response.data.map(applyOverridesAndFillEmptyValues)
                                .map(school => {
                                    const exchange = this.schoolExchanges
                                        .find(schoolExchange => schoolExchange.id === school.schoolExchangeId);
                                    if (!(exchange)) {
                                        return school;
                                    }
                                    return {
                                        ...school,
                                        amountOfSchoolExchangeStudents: exchange.amountOfStudents,
                                    }
                                })
                                .sort((schoolA, schoolB) => {
                                    const schoolAHasSchoolExchangeStudents = !!schoolA.amountOfSchoolExchangeStudents;
                                    const schoolBHasSchoolExchangeStudents = !!schoolB.amountOfSchoolExchangeStudents;
                                    return schoolBHasSchoolExchangeStudents - schoolAHasSchoolExchangeStudents;
                                });
                            if (schoolBackup) {
                                this.schools = _.unionBy([schoolBackup], this.schools, school => `${school.id}-${school.name}`);
                            }
                            this.searchIsRunning = false;
                        });
                }
            }, 500);
        }
    }
</script>

<style scoped>

</style>
