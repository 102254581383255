import { render, staticRenderFns } from "./CreateGroupComponent.vue?vue&type=template&id=7d30066f&scoped=true&"
import script from "./CreateGroupComponent.vue?vue&type=script&lang=js&"
export * from "./CreateGroupComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d30066f",
  null
  
)

export default component.exports