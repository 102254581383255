<template>
    <div>
        <li
                :class="`cursor-pointer list-group-item list-group-item-action list-group-item-no-vertical-border ${listGroupItemClass}`"
                @click="openMail"
        >
            <div class="d-flex flex-wrap flex-md-nowrap w-100 justify-content-between">
                <h5 class="mb-1 mr-2 flex-grow-1 text-truncate">{{ from }}</h5>
                <dynamic-from-now-component v-b-tooltip.hover :title="mail.date | dayjs"
                                            :value="mail.date" :interval="2000" tag="small"
                                            class="flex-shrink-0"></dynamic-from-now-component>
            </div>
            <div class="d-flex flex-wrap w-100 justify-content-between">
                <div class="d-flex w-100">
                    <p class="mb-1 text-truncate flex-grow-1">
                        {{ mail.mail.subject }}
                    </p>
                    <span v-if="this.hasAttachments" class="ml-2 mt-0 flex-shrink-0">
                        <i class="fas fa-paperclip"></i>
                    </span>
                </div>

                <div
                        v-if="this.hasReplyDraft"
                        class="mt-1 ml-0"
                >
                    <span class="badge badge-white rounded shadow-sm mb-2">
                        <span class="badge badge-icon">
                            <i class="fas fa-pencil-alt"></i>
                        </span>
                        Antwort-Entwurf
                    </span>
                </div>
            </div>
        </li>
        <b-modal
                :id="`modal-mail-${mail.mail.id}`"
                v-model="modalShow"
                :title="modalTitle"
                size="lg"
                scrollable
        >
            <h5>{{ mail.mail.subject }}</h5>

            <dl class="d-flex justify-content-between flex-column font-weight-lighter">
                <div>
                    <dt class="d-inline">Von:</dt>
                    <dd class="d-inline">{{ from }}</dd>
                </div>

                <div>
                    <dt class="d-inline">{{ dateLabel }}:</dt>
                    <dd class="d-inline">{{ mail.date | dayjs }}</dd>
                </div>

                <div>
                    <dt class="d-inline">An:</dt>
                    <dd class="d-inline">{{ to }}</dd>
                </div>
            </dl>

            <h6 v-if="hasAttachments">Anhänge</h6>

            <ul v-if="hasAttachments" class="mb-3">
                <li v-for="file in mail.mail.files" v-bind:key="file.id">
                    <a :href="`/api/v1/file/${file.id}`" target="_blank">{{ file.originalFilename }}</a>
                </li>
            </ul>

            <div
                    class="mail__content"
                    v-html="mail.mail.body"
            ></div>

            <div slot="modal-footer">
                <school-exchange-compose-mail-component
                        v-if="this.mail.type === 'INCOMING_MAIL'"
                        :school-exchange-id="this.schoolExchangeId"
                        :body-placeholder="this.replyBodyPlaceholder"
                        :subject-placeholder="this.replySubjectPlaceholder"
                        :to-placeholder="this.replyToPlaceholder"
                        :auto-attached-text="this.autoAttachedText"
                        :custom-local-storage-key="this.draftLocalStorageKey"
                        button-text="Antworten"
                        :reply-to-incoming-mail-id="this.mail.mail.id"
                ></school-exchange-compose-mail-component>
                <b-button
                        variant="secondary"
                        @click="closeModal"
                >
                    Schließen
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import dayjs from 'dayjs';
    import 'dayjs/locale/de';
    import SchoolExchangeComposeMailComponent from "./SchoolExchangeComposeMailComponent";
    import DynamicFromNowComponent from "./DynamicFromNowComponent";

    // TODO Parse the mail body in order to split into the actual email and the .gmail_quote content
    // TODO Then, put the .gmail_quote content into a Bootstrap collapsible section with a corresponding button

    export default {
        props: {
            schoolExchangeId: Number,
            autoAttachedText: String,
            mail: Object,
        },
        data: function() {
            return {
                modalShow: false,
            }
        },
        methods: {
            openMail: function() {
                this.modalShow = true;
                if (!this.mail.mail.isReadByCurrentUser) {
                    axios.patch(`/api/v1/schoolExchange/${this.schoolExchangeId}/incomingMail/${this.mail.mail.id}/markAsRead`);
                    this.mail.mail.isReadByCurrentUser = true;
                }
            },
            closeModal: function() {
                this.modalShow = false;
            },
            localStorageSingleKey: function(type) {
                return `drafts.schoolExchangeOutgoingMails.${this.schoolExchangeId}.${this.draftLocalStorageKey}.${type}`;
            }
        },
        filters: {
            dayjs: function(args) {
                return dayjs(args).locale('de').format("dddd, DD.MM.YYYY HH:mm");
            }
        },
        computed: {
            draftLocalStorageKey: function() {
                return `reply-${this.mail.type}-${this.mail.mail.id}`;
            },
            hasReplyDraft: function() {
                return (!!localStorage[this.localStorageSingleKey('body')]
                     || !!localStorage[this.localStorageSingleKey('subject')]
                     || !!localStorage[this.localStorageSingleKey('to')]);
            },
            hasAttachments: function() {
                return this.mail.mail.files && this.mail.mail.files.length > 0;
            },
            listGroupItemClass: function() {
                if (this.mail.type === "INCOMING_MAIL") {
                    if (!!this.mail.mail.isReadByCurrentUser) {
                        return "";
                    }
                    // email is unread
                    return "list-group-item-primary";
                }
                if (this.mail.type === "OUTGOING_MAIL") {
                    return "list-group-item-secondary";
                }
            },
            from: function() {
                if (this.mail.type === "OUTGOING_MAIL") {
                    return "Schulkontaktportal";
                }
                if (!!this.mail.mail.fromName) {
                    return `${this.mail.mail.fromName} <${this.mail.mail.fromEmail}>`;
                }
                return this.mail.mail.fromEmail;
            },
            to: function() {
                if (this.mail.type === "INCOMING_MAIL") {
                    return "Schulkontaktportal";
                }
                return this.mail.mail.to;
            },
            modalTitle: function() {
                let modalTitle = "E-Mail";
                if (this.mail.type === "INCOMING_MAIL") {
                    modalTitle = "Eingegangene E-Mail";
                }
                if (this.mail.type === "OUTGOING_MAIL") {
                    modalTitle = "E-Mail im Postausgang";
                    if (this.mail.mail.sentAt) {
                        modalTitle = "Gesendete E-Mail";
                    }
                }
                return `${modalTitle} vom ${dayjs(this.mail.date).locale('de').format('DD.MM.YYYY')} um ${dayjs(this.mail.date).locale('de').format('HH:mm')} Uhr`;
            },
            dateLabel: function() {
                let dateLabel = "Datum";
                if (this.mail.type === "INCOMING_MAIL") {
                    dateLabel = "Empfangen";
                }
                if (this.mail.type === "OUTGOING_MAIL") {
                    dateLabel = "Gespeichert";
                    if (this.mail.mail.sentAt) {
                        dateLabel = "Gesendet";
                    }
                }
                return dateLabel;
            },
            replyToPlaceholder: function() {
                return this.mail.mail.fromEmail;
            },
            replySubjectPlaceholder: function() {
                return `Re: ${this.mail.mail.subject}`;
            },
            replyBodyPlaceholder: function() {
                const blockquoteHeader = `Am ${dayjs(this.mail.date).locale('de').format('dd., D. MMMM YYYY')} um ${dayjs(this.mail.date).locale('de').format('HH:mm')} Uhr schrieb ${this.mail.mail.fromName} &lt;<a href="mailto:${this.mail.mail.fromEmail}" target="_blank">${this.mail.mail.fromEmail}</a>&gt;:`;
                return `<p></p><p>${blockquoteHeader}</p><blockquote>${this.mail.mail.body}</blockquote>`;
            },
        },
        name: "school-exchange-mail-entry-component",
        components: {
            SchoolExchangeComposeMailComponent,
            DynamicFromNowComponent,
        }
    }

</script>

<style scoped>

    .mail__content >>> blockquote {
        border-left: 3px solid rgba(0, 0, 0, 0.1) !important;
        color: rgba(0, 0, 0, 0.8) !important;
        padding-left: .8rem !important;
    }

</style>
