<template>

    <div>
        <b-modal
            id="modal-add-calendar-entry"
            v-model="modalShow"
            size="lg"
            scrollable
            modal-class="modal-fullscreen-bottom-footer"
            title="Kalendereintrag hinzufügen"
        >
            <b-form @submit.prevent="onSubmit">
                <b-form-group
                    id="calendar-entry-status-input-group"
                    label-cols-sm="2"
                    label="Status:*"
                    label-for="calendar-entry-status-input"
                    label-align-sm="right"
                >
                    <vue-bootstrap-typeahead
                        id="calendar-entry-status-input"
                        placeholder='bspw. "Termin vereinbart"'
                        :data="calendarEntryStatuses"
                        :serializer="serializeCalendarEntryStatus"
                        @hit="calendarEntryStatusNameTypeaheadHit"
                        :min-matching-chars="0"
                        :max-matches="255"
                        :query-transformer="queryTransformer"
                        :disabled="isSubmitting"
                        v-validate.immediate="{
                            required: true
                        }"
                        :input-class="validateState('status-input') ? 'is-valid' : 'is-invalid'"
                        data-vv-validate-on="change|hit"
                        name="status-input"
                        input-name="status-input"
                        aria-describedby="status-input-live-feedback"
                    />

                    <b-form-invalid-feedback id="status-input-live-feedback" :class="validateState('status-input') ? 'd-none' : 'd-block'">
                        Bitte wähle einen Status für den Kalendereintrag aus.
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="from-date-input-group"
                    label-cols-sm="2"
                    label="Von:*"
                    label-for="from-date-input"
                    label-align-sm="right"
                >
                    <b-form-input
                        id="from-date-input"
                        name="from-date-input"
                        type="date"
                        required
                        v-model="newCalendarEntry.fromDate"
                        v-validate.immediate="{
                            required: true,
                            date_format: 'yyyy-MM-dd',
                            valid_date_range: true
                        }"
                        ref="fromDateInput"
                        data-vv-validate-on="change|blur"
                        :state="validateState('from-date-input')"
                        aria-describedby="from-date-input-live-feedback"
                        :disabled="isSubmitting"
                    ></b-form-input>

                    <b-form-invalid-feedback id="from-date-input-live-feedback">
                        Bitte gib das Datum an, von dem an der Kalendereintrag gilt. (darf nicht nach dem <em>Bis</em>-Datum liegen).
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="to-date-input-group"
                    label-cols-sm="2"
                    label="Bis:*"
                    label-for="to-date-input"
                    label-align-sm="right"
                >
                    <b-form-input
                        id="to-date-input"
                        name="to-date-input"
                        type="date"
                        required
                        v-model="newCalendarEntry.toDate"
                        v-validate.immediate="{
                            required: true,
                            date_format: 'yyyy-MM-dd',
                            valid_date_range: true
                        }"
                        ref="toDateInput"
                        data-vv-validate-on="change|blur"
                        :state="validateState('to-date-input')"
                        aria-describedby="to-date-input-live-feedback"
                        :disabled="isSubmitting"
                    ></b-form-input>

                    <b-form-invalid-feedback id="to-date-input-live-feedback">
                        Bitte gib das Datum an, bis zu dem der Kalendereintrag gilt (darf nicht vor dem <em>Von</em>-Datum liegen).
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="school-input-group"
                    label-cols-sm="2"
                    label="Schule:*"
                    label-for="school-input"
                    label-align-sm="right"
                >
                    <vue-bootstrap-typeahead
                        id="school-input"
                        placeholder="Wähle die Schule aus, für die der Eintrag gilt …"
                        :data="schools"
                        :serializer="serializeSchool"
                        @hit="schoolNameTypeaheadHit"
                        :min-matching-chars="0"
                        :max-matches="255"
                        :query-transformer="queryTransformer"
                        :disabled="isSubmitting"
                        v-validate.immediate="{
                            required: true
                        }"
                        :input-class="validateState('school-input') ? 'is-valid' : 'is-invalid'"
                        data-vv-validate-on="change|hit"
                        name="school-input"
                        input-name="school-input"
                        aria-describedby="school-input-live-feedback"
                    >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span>
                                <span v-html="htmlText"></span>
                                <span class="d-block text-muted small">{{ data.address ? `${data.address} ` : '' }}{{ data.state ? `(${data.state.name})` : '' }}</span>
                                <span v-if="data.schoolType" class="d-block text-muted small"><em>{{ data.schoolType }}</em></span>
                            </span>
                        </template>
                    </vue-bootstrap-typeahead>

                    <b-form-invalid-feedback id="school-input-live-feedback" :class="validateState('school-input') ? 'd-none' : 'd-block'">
                        Bitte wähle die Schule aus, für die der Kalendereintrag gelten soll.
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form>

            <div slot="modal-footer">
                <b-button
                    variant="secondary"
                    @click="modalShow = false"
                    :disabled="isSubmitting"
                >
                    Abbrechen
                </b-button>
                <b-button
                    variant="primary"
                    @click="onSubmit"
                    :disabled="veeErrors.any() || isSubmitting"
                >
                    {{ this.isSubmitting ? 'Bitte warten …' : 'Kalendereintrag anlegen' }}
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="modal-calendar-entry-details"
            v-if="showModalForCalendarEntry"
            v-model="showModalForCalendarEntry"
            size="md"
            modal-class="modal-fullscreen-bottom-footer"
            title="Details zum Kalendereintrag"
        >
            <dl class="d-flex justify-content-between flex-column">
                <div>
                    <dt class="d-inline">Erstellt von:</dt>
                    <dd class="d-inline">{{ currentlyViewedCalendarEntry.author.name }}</dd>
                </div>
                <div>
                    <dt class="d-inline">Status:</dt>
                    <dd class="d-inline">{{ currentlyViewedCalendarEntry.status.name }}</dd>
                </div>
                <div>
                    <dt class="d-inline">Von:</dt>
                    <dd class="d-inline">{{ currentlyViewedCalendarEntry.fromDate | dayjs }}</dd>
                </div>
                <div>
                    <dt class="d-inline">Bis:</dt>
                    <dd class="d-inline">{{ currentlyViewedCalendarEntry.toDate | dayjs }}</dd>
                </div>
                <div>
                    <dt class="d-inline">Schule:</dt>
                    <dd class="d-inline">{{ currentlyViewedCalendarEntry.schoolExchange.school.name }}</dd>
                </div>
            </dl>
            <div slot="modal-footer" class="w-100 d-flex">
                <b-button
                    variant="outline-danger"
                    @click="deleteCurrentlyViewedCalendarEntry"
                    class="mr-auto"
                    :disabled="isDeletingCurrentlyViewedCalendarEntry"
                >
                    Löschen
                </b-button>
                <b-button
                    variant="secondary"
                    @click="closeCalendarEntryDetailModal"
                    :disabled="isDeletingCurrentlyViewedCalendarEntry"
                >
                    Schließen
                </b-button>
            </div>
        </b-modal>
        <full-calendar
            ref="fullCalendar"
            :selectable="true"
            @select="handleCalendarSelect"
            default-view="dayGridMonth"
            :plugins="calendarPlugins"
            theme-system="bootstrap"
            :locale="calendarLocale"
            height="auto"
            :header="calendarHeader"
            :footer="calendarFooter"
            :views="calendarViews"
            :title-range-separator="'\u2013'"
            :bootstrap-font-awesome="calendarBootstrapFontAwesome"
            v-bind:events="calendarEvents"
            :custom-buttons="calendarCustomButtons"
            @eventClick="onEventClick"
        />
    </div>

</template>

<script>

    import applyConverters from 'axios-case-converter';
    import dayjs from 'dayjs';
    import 'dayjs/locale/de';

    import applyOverridesAndFillEmptyValues from '../utilities/SchoolUtilities';

    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

    import FullCalendar from '@fullcalendar/vue'
    import interactionPlugin from '@fullcalendar/interaction'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import listPlugin from '@fullcalendar/list'
    import bootstrapPlugin from '@fullcalendar/bootstrap'
    import deLocale from '@fullcalendar/core/locales/de'

    export default {
        props: {
            calendarEntriesApiUrl: String,
            createCalendarEntryApiUrl: String,
            destroyCalendarEntryApiUrl: String,
            calendarEntryStatusesApiUrl: String,
            schoolsApiUrl: String,
        },
        data: function() {
            return {
                axiosClient: null,
                modalShow: false,
                validationErrors: [],
                isSubmitting: false,
                calendarPlugins: [ dayGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin ],
                calendarLocale: deLocale,
                calendarViews: {
                    dayGridMonth: {
                        titleFormat: { year: 'numeric', month: 'long' },
                    },
                    listMonth: {
                        noEventsMessage: "Für diesen Monat gibt es bisher keine Eintragungen im Kalender.",
                    }
                },
                calendarBootstrapFontAwesome: {
                    dayGridMonth: 'fa-calendar-alt',
                    listMonth: 'fa-list-ul'
                },
                calendarHeader: {
                    left: 'title',
                    center: '',
                    right: 'dayGridMonth,listMonth',
                },
                calendarFooter: {
                    left: 'addEntryButton',
                    center: '',
                    right: 'today prev,next',
                },
                calendarEvents: [],
                calendarEntryStatuses: [],
                newCalendarEntry: {
                    fromDate: undefined,
                    toDate: undefined
                },
                calendarCustomButtons: {
                    addEntryButton: {
                        text: 'Neuen Eintrag anlegen',
                        click: this.handleAddEntryButtonClick,
                    },
                },
                schools: [],
                showModalForCalendarEntry: false,
                showModalForCalendarEntryId: undefined,
                isDeletingCurrentlyViewedCalendarEntry: false,
            }
        },
        computed: {
            formIsCompleted: function () {
                return (
                    this.newCalendarEntry
                    && this.newCalendarEntry.fromDate !== undefined
                    && this.newCalendarEntry.toDate !== undefined
                    && this.newCalendarEntry.schoolId !== undefined
                    && this.newCalendarEntry.calendarEntryStatusId !== undefined
                );
            },
            currentlyViewedCalendarEntry: function() {
                if (!(this.showModalForCalendarEntry) || !(this.showModalForCalendarEntryId)) {
                    return null;
                }
                const calendarEntry = this.calendarEvents
                    .find(calendarEvent => {
                        return calendarEvent.extendedProps.calendarEntry.id === this.showModalForCalendarEntryId
                    })
                    .extendedProps
                    .calendarEntry;
                return {
                    ...calendarEntry,
                    schoolExchange: {
                        ...calendarEntry.schoolExchange,
                        school: applyOverridesAndFillEmptyValues(calendarEntry.schoolExchange.school),
                    },
                };
            },
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            handleCalendarSelect: function(selectionInfo) {
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.unselect();
                this.modalShow = true;
                this.newCalendarEntry.fromDate = selectionInfo.startStr;
                this.newCalendarEntry.toDate = moment(selectionInfo.endStr, "YYYY-MM-DD").subtract(1, 'minutes').format('YYYY-MM-DD');
            },
            handleAddEntryButtonClick: function(event) {
                this.modalShow = true;
            },
            convertCalendarEntriesToFullcalendarEvents: function (calendarEntries) {
                return calendarEntries.map(calendarEntry => {
                    const school = {
                        ...calendarEntry.schoolExchange.school,
                        ...calendarEntry.schoolExchange.school.overriddenFields,
                    };
                    return {
                        allDay: true,
                        title: school.name,
                        className: "cursor-pointer",
                        start: calendarEntry.fromDate,
                        end: moment(calendarEntry.toDate, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').format('YYYY-MM-DD'),
                        extendedProps: {
                            calendarEntry: calendarEntry
                        },
                        backgroundColor: !!calendarEntry.status.isAccepted ? "#8CC552" : (calendarEntry.status.isAccepted === false) ? "#D6402F" : undefined,
                        borderColor: !!calendarEntry.status.isAccepted ? "#69A42D" : (calendarEntry.status.isAccepted === false) ? "#D6402F" : undefined,
                    }
                });
            },
            loadCalendarEntries: function() {
                this.axiosClient.get(this.calendarEntriesApiUrl)
                    .then(response => {
                        this.calendarEvents = this.convertCalendarEntriesToFullcalendarEvents(response.data);
                        this.updateCalendarSize();
                    });
            },
            updateCalendarSize: function() {
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.updateSize();
            },
            loadCalendarEntryStatuses: function() {
                this.axiosClient.get(this.calendarEntryStatusesApiUrl)
                    .then(response => {
                        this.calendarEntryStatuses = response.data;
                        // TODO Auto-select the entry if there is only one
                    });
            },
            loadSchools: function() {
                this.axiosClient.get(this.schoolsApiUrl)
                    .then(response => {
                        this.schools = response.data.map(applyOverridesAndFillEmptyValues);
                        // TODO Auto-select the entry if there is only one
                    });
            },
            validateState(ref) {
                if (
                    this.veeFields[ref] &&
                    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
                ) {
                    return !this.veeErrors.has(ref);
                }
                return null;
            },
            queryTransformer: function(queryString) {
                return queryString.replace(/[ -]/g, '[ -]')
            },
            serializeSchool: function(item) {
                return item.name;
            },
            serializeCalendarEntryStatus: function(item) {
                return item.name;
            },
            schoolNameTypeaheadHit: function (data) {
                this.newCalendarEntry.schoolId = data.id;
            },
            calendarEntryStatusNameTypeaheadHit: function (data) {
                this.newCalendarEntry.calendarEntryStatusId = data.id;
            },
            onEventClick: function({ event }) {
                this.showModalForCalendarEntry = true;
                this.showModalForCalendarEntryId = event.extendedProps.calendarEntry.id;
            },
            closeCalendarEntryDetailModal: function() {
                this.showModalForCalendarEntry = false;
                this.showModalForCalendarEntryId = undefined;
            },
            deleteCurrentlyViewedCalendarEntry: async function(event) {
                event.preventDefault();
                try {
                    const confirmation = await this.$bvModal.msgBoxConfirm('Bist du sicher, dass du den Kalendereintrag löschen möchtest?', {
                        title: 'Kalendereintrag löschen',
                        size: 'md',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'Ja, löschen',
                        cancelTitle: 'Nein, abbrechen',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    });
                    if (!!confirmation && confirmation) {
                        this.isDeletingCurrentlyViewedCalendarEntry = true;
                        try {
                            await this.axiosClient.delete(`${this.destroyCalendarEntryApiUrl}/${this.showModalForCalendarEntryId}`);
                            const indexOfCalendarEventToDelete = this.calendarEvents
                                .findIndex(calendarEvent => calendarEvent.extendedProps.calendarEntry.id === this.showModalForCalendarEntryId);
                            this.calendarEvents.splice(indexOfCalendarEventToDelete, 1);
                        } catch (error) {
                            console.error(error);
                        } finally {
                            this.isDeletingCurrentlyViewedCalendarEntry = false;
                            this.closeCalendarEntryDetailModal();
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            onSubmit: async function(event) {

                event.preventDefault();

                if (!(this.formIsCompleted)) {
                    return;
                }

                await this.$validator.validateAll().then(async (result) => {
                    if (!result) {
                        return;
                    }

                    this.isSubmitting = true;

                    let newlyAddedCalendarEntry = undefined;

                    try {
                        const response = await this.axiosClient.post(this.createCalendarEntryApiUrl, {
                            ...this.newCalendarEntry,
                        });
                        newlyAddedCalendarEntry = response.data;
                    } catch (error) {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                        this.isSubmitting = false;
                        return;
                    }

                    this.isSubmitting = false;

                    // TODO Reset the modal's contents (do that in general when the modal is hidden – maybe watch?)
                    this.modalShow = false;

                    this.calendarEvents.push(this.convertCalendarEntriesToFullcalendarEvents([newlyAddedCalendarEntry])[0]);
                });
            },
        },
        filters: {
            dayjs: function(args) {
                return dayjs(args).locale('de').format("dddd, DD.MM.YYYY");
            }
        },
        watch: {
            'newCalendarEntry.fromDate': {
                handler: function() {
                    this.$refs.fromDateInput ? this.$refs.fromDateInput.$el.dispatchEvent(new Event('change')) : undefined;
                    this.$refs.toDateInput ? this.$refs.toDateInput.$el.dispatchEvent(new Event('change')) : undefined;
                },
                deep: true
            },
            'newCalendarEntry.toDate': {
                handler: function() {
                    this.$refs.fromDateInput ? this.$refs.fromDateInput.$el.dispatchEvent(new Event('change')) : undefined;
                    this.$refs.toDateInput ? this.$refs.toDateInput.$el.dispatchEvent(new Event('change')) : undefined;
                },
                deep: true
            }
        },
        inject: [
            '$validator'
        ],
        created: function() {
            this.$validator.extend('valid_date_range', {
                validate: () => {
                    return !!this.newCalendarEntry.fromDate && !!this.newCalendarEntry.toDate && moment(this.newCalendarEntry.fromDate, "YYYY-MM-DD").isSameOrBefore(moment(this.newCalendarEntry.toDate, "YYYY-MM-DD"));
                }
            });
        },
        mounted: function () {
            this.initializeAxiosClient();
            this.loadCalendarEntries();
            this.loadCalendarEntryStatuses();
            this.loadSchools();
        },
        name: "calendar-component",
        components: {
            FullCalendar,
            VueBootstrapTypeahead,
        },
    }

</script>

<style lang='scss'>

    @import '../../sass/color_palette';

    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/list/main.css';
    @import '~@fullcalendar/bootstrap/main.css';

</style>
