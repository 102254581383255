<template>
    <ol class="list-group list-group-flush ml-n3 mr-n3 mb-2">
        <school-exchange-contact-protocol-form-component
            :school-exchange-id="this.schoolExchangeId"
            :create-protocol-entry-api-url="this.createProtocolEntryApiUrl"
        ></school-exchange-contact-protocol-form-component>
        <school-exchange-contact-protocol-entries-component
            :school-exchange-id="this.schoolExchangeId"
            :no-entries-yet-placeholder="this.noEntriesYetPlaceholder"
            :destroy-protocol-entry-api-url="this.destroyProtocolEntryApiUrl"
        ></school-exchange-contact-protocol-entries-component>
    </ol>
</template>

<script>

    import SchoolExchangeContactProtocolFormComponent from "./SchoolExchangeContactProtocolFormComponent";
    import SchoolExchangeContactProtocolEntriesComponent from "./SchoolExchangeContactProtocolEntriesComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            noEntriesYetPlaceholder: String,
            createProtocolEntryApiUrl: String,
            destroyProtocolEntryApiUrl: String,
        },
        name: "school-exchange-contact-protocol-component",
        components: {
            SchoolExchangeContactProtocolFormComponent,
            SchoolExchangeContactProtocolEntriesComponent,
        },
    }

</script>

<style scoped>

</style>
