<template>
    <div class="md-overflow-y-scroll md-max-height-20">
        <div
                v-if="allMails.length === 0"
        >
            <li class="list-group-item list-group-item-no-vertical-border">
                <em class="text-muted" v-html="this.noMailsYetPlaceholder"></em>
            </li>
        </div>
        <school-exchange-mail-entry-component
                v-else
                v-for="mail in allMails"
                :key="`${mail.type}-${mail.mail.id}`"
                :school-exchange-id="schoolExchangeId"
                :mail="mail"
                :auto-attached-text="autoAttachedText"
        >
        </school-exchange-mail-entry-component>
    </div>
</template>

<script>

    import SchoolExchangeMailEntryComponent from "./SchoolExchangeMailEntryComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            autoAttachedText: String,
            noMailsYetPlaceholder: String,
        },
        data() {
            return {
                incomingMails: [],
                outgoingMails: [],
            }
        },
        computed: {
            allMails: function() {
                let allMails = [];

                allMails = allMails.concat(this.incomingMails.map((incomingMail) => {
                    return {
                        date: incomingMail.receivedAt || incomingMail.updatedAt,
                        type: 'INCOMING_MAIL',
                        mail: incomingMail,
                    }
                }));
                allMails = allMails.concat(this.outgoingMails.map((outgoingMail) => {
                    return {
                        date: outgoingMail.sentAt || outgoingMail.updatedAt,
                        type: 'OUTGOING_MAIL',
                        mail: outgoingMail,
                    }
                }));
                allMails = allMails.sort((mailA, mailB) => {
                    return new Date(mailB.date) - new Date(mailA.date)
                });
                return allMails;
            }
        },
        mounted() {
            axios.get(`/api/v1/schoolExchange/${this.schoolExchangeId}/incomingMail`).then(response => {
                this.incomingMails = response.data;
            });
            axios.get(`/api/v1/schoolExchange/${this.schoolExchangeId}/outgoingMail`).then(response => {
                this.outgoingMails = response.data;
            });
        },
        name: "school-exchange-mail-list-component",
        components: {
            SchoolExchangeMailEntryComponent,
        }
    }

</script>

<style scoped>

</style>
