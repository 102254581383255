<template>
    <li class="list-group-item list-group-item-no-vertical-border">
        <div class="input-group">
            <textarea-autosize
                    id="body"
                    cols="28"
                    rows="1"
                    class="form-control"
                    aria-label="Chat-Nachricht"
                    placeholder="Schreibe eine Nachricht …"
                    @keydown="typing"
                    :min-height="30"
                    :max-height="250"
                    v-model="body"
            ></textarea-autosize>
            <div class="input-group-append align-items-end">
                <button
                        class="btn btn-primary"
                        type="button"
                        id="button-send-chat-message"
                        v-on:click="sendMessage"
                >
                    Senden
                </button>
            </div>
        </div>
    </li>
</template>

<script>

    import Event from '../event.js';

    export default {
        data() {
            return {
                body: null,
            }
        },
        methods: {
            typing(e) {
                if(e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    this.sendMessage();
                }
            },
            sendMessage() {
                if(!this.body || this.body.trim() === '') {
                    return;
                }
                const messageObj = this.buildMessage();
                Event.$emit('added_message', messageObj);

                axios.post(`/api/v1/schoolExchange/${this.schoolExchangeId}/chatMessage`, {
                    body: this.body.trim(),
                }).catch(error => {
                    console.log(error);
                });

                this.body = null;
            },
            buildMessage() {
                return {
                    id: Date.now(),
                    created_at: new Date(Date.now()).toUTCString(),
                    body: this.body,
                    is_created_by_current_user: true,
                    student: {
                        name: Laravel.user.student.name
                    }
                }
            }
        },
        props: {
            schoolExchangeId: Number,
        },
        name: "school-exchange-chat-form-component"
    }
</script>

<style scoped>

</style>
