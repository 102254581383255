<script>

    // Taken from https://stackoverflow.com/a/44305575
    export default {
        props: {
            tag: {
                type: String,
                default: 'span'
            },
            value: {
                type: String,
                default: () => moment().toISOString()
            },
            interval: {
                type: Number,
                default: 1000
            }
        },
        data() {
            return {
                fromNow: moment(this.value).fromNow()
            }
        },
        mounted () {
            this.intervalId = setInterval(this.updateFromNow, this.interval);
            this.$watch('value', this.updateFromNow);
        },
        beforeDestroy() {
            clearInterval(this.intervalId)
        },
        methods: {
            updateFromNow() {
                var newFromNow = moment(this.value).fromNow(this.dropFixes);
                if (newFromNow !== this.fromNow) {
                    this.fromNow = newFromNow
                }
            },
        },
        render(h) {
            return h(this.tag, this.fromNow)
        },
        name: "dynamic-from-now-component",
    }

</script>
