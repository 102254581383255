export default function applyOverridesAndFillEmptyValues(school) {
    const overriddenFields = typeof school.overriddenFields === 'string' ? JSON.parse(school.overriddenFields) : school.overriddenFields;
    const cleanedUpSchool = {
        ...school,
        ...overriddenFields,
    };
    if (!(school.name && school.name !== "")) {
        cleanedUpSchool.name = "(Schule ohne Name)";
    }
    return cleanedUpSchool;
}
