/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

moment.locale('de');

import BootstrapVue from 'bootstrap-vue';
import lineClamp from 'vue-line-clamp';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VeeValidate from 'vee-validate';

import Vue from 'vue';
window.Vue = Vue;

Vue.use(BootstrapVue);
Vue.use(lineClamp);
Vue.use(VueTextareaAutosize);
Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: 'veeFields',
    // This is not required but avoids possible naming conflicts
    errorBagName: 'veeErrors'
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

import CreateSchoolExchangeComponent from './components/CreateSchoolExchangeComponent.vue';
Vue.component('create-school-exchange-component', CreateSchoolExchangeComponent);

import CreateGroupComponent from './components/CreateGroupComponent.vue';
Vue.component('create-group-component', CreateGroupComponent);

import SchoolExchangeChatComponent from "./components/SchoolExchangeChatComponent";
Vue.component('school-exchange-chat-component', SchoolExchangeChatComponent);

import SchoolExchangeMailComponent from "./components/SchoolExchangeMailComponent";
Vue.component('school-exchange-mail-component', SchoolExchangeMailComponent);

import SchoolExchangeContactProtocolComponent from "./components/SchoolExchangeContactProtocolComponent";
Vue.component('school-exchange-contact-protocol-component', SchoolExchangeContactProtocolComponent);

import SchoolLearningsComponent from "./components/SchoolLearningsComponent";
Vue.component('school-learnings-component', SchoolLearningsComponent);

import CalendarComponent from "./components/CalendarComponent";
Vue.component('calendar-component', CalendarComponent);

import NewlyJoinedStudentsInfoComponent from "./components/NewlyJoinedStudentsInfoComponent";
Vue.component('newly-joined-students-info-component', NewlyJoinedStudentsInfoComponent);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});
