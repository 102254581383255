<template>
    <ol class="list-group ml-n3 mr-n3 mb-2">
        <li class="list-group-item">
            <div class="d-flex w-100 justify-content-center">
                <school-exchange-compose-mail-component
                        :school-exchange-id="this.schoolExchangeId"
                        :body-placeholder="this.composeBodyPlaceholder"
                        :subject-placeholder="this.composeSubjectPlaceholder"
                        :to-placeholder="this.composeToPlaceholder"
                        :auto-attached-text="this.autoAttachedText"
                ></school-exchange-compose-mail-component>
            </div>
        </li>
        <school-exchange-mail-list-component
                :school-exchange-id="this.schoolExchangeId"
                :no-mails-yet-placeholder="this.noMailsYetPlaceholder"
                :auto-attached-text="this.autoAttachedText"
        ></school-exchange-mail-list-component>
    </ol>
</template>

<script>

    import SchoolExchangeComposeMailComponent from "./SchoolExchangeComposeMailComponent";
    import SchoolExchangeMailListComponent from "./SchoolExchangeMailListComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            composeBodyPlaceholder: String,
            composeSubjectPlaceholder: String,
            composeToPlaceholder: String,
            noMailsYetPlaceholder: String,
            autoAttachedText: String,
        },
        name: "school-exchange-mail-component",
        components: {
            SchoolExchangeComposeMailComponent,
            SchoolExchangeMailListComponent,
        },
    }

</script>

<style scoped>

</style>
