<template>
    <div class="d-inline-block">
        <b-button
                @click="modalShow = !modalShow"
                variant="primary"
        >
            {{ buttonText }}
        </b-button>

        <b-modal
                id="modal-compose-mail"
                v-model="modalShow"
                title="E-Mail schreiben"
                size="lg"
                scrollable
        >
            <b-alert
                :show="success"
                variant="success"
            >
                Die E-Mail wurde verschickt!
            </b-alert>

            <b-alert
                :show="validationErrors.length > 0"
                variant="warning"
            >
                Bitte überprüfe deine Eingaben:
                <ul>
                    <li
                        v-for="error in validationErrors"
                    >
                        {{ error }}
                    </li>
                </ul>
            </b-alert>

            <b-alert
                :show="errorOnSubmit"
                variant="danger"
            >
                <p>Die E-Mail konnte nicht auf dem Server zum Versenden hinterlegt werden.</p>
                <p>Bitte versuche es später erneut oder kontaktiere die Administratoren des Schulkontakt-Portals, falls der Fehler bestehen bleibt.</p>
            </b-alert>

            <b-form-group
                    v-if="!success"
                    label-cols-sm="3"
                    label-cols-lg="2"
                    label="An:"
                    label-for="input-to"
            >
                <b-form-input
                        id="input-to"
                        v-model="to"
                        type="text"
                        required
                        :placeholder="toPlaceholder"
                        @blur="to = to.trim()"
                >
                </b-form-input>
            </b-form-group>
            <h5
                    v-if="success"
            >
                <strong>An:</strong> {{ to }}
            </h5>

            <b-form-group
                    v-if="!success"
                    label-cols-sm="3"
                    label-cols-lg="2"
                    label="Betreff:"
                    label-for="input-subject"
            >
                <b-form-input
                        id="input-subject"
                        v-model="subject"
                        type="text"
                        required
                        :placeholder="subjectPlaceholder"
                        @blur="subject = subject.trim()"
                >
                </b-form-input>
            </b-form-group>
            <h5
                    v-if="success"
            >
                <strong>Betreff:</strong> {{ subject }}
            </h5>

            <editor-content
                    v-if="!success"
                    :editor="editor"
                    class="form-control editor__content"
            />
            <editor-menu-bar
                    v-if="!success"
                    :editor="editor"
                    v-slot="{ commands, isActive }"
                    class="editor__menu-bar"
            >
                <div class="mb-2">
                    <b-button-group
                            size="sm"
                    >
                        <b-button
                                variant="outline-dark"
                                @click="commands.undo"
                        >
                            <i class="fas fa-undo"></i>
                        </b-button>
                        <b-button
                                variant="outline-dark"
                                @click="commands.redo"
                        >
                            <i class="fas fa-redo"></i>
                        </b-button>
                    </b-button-group>
                    <b-button-group
                            size="sm"
                    >
                        <b-button
                                variant="outline-dark"
                                :pressed="isActive.bold()"
                                @click="commands.bold"
                        >
                            <i class="fas fa-bold"></i>
                        </b-button>
                        <b-button
                                variant="outline-dark"
                                :pressed="isActive.italic()"
                                @click="commands.italic"
                        >
                            <i class="fas fa-italic"></i>
                        </b-button>
                        <b-button
                                variant="outline-dark"
                                :pressed="isActive.underline()"
                                @click="commands.underline"
                        >
                            <i class="fas fa-underline"></i>
                        </b-button>
                    </b-button-group>
                    <b-button-group
                            size="sm"
                    >
                        <b-button
                                variant="outline-dark"
                                :pressed="isActive.blockquote()"
                                @click="commands.blockquote"
                        >
                            <i class="fas fa-quote-right"></i>
                        </b-button>
                    </b-button-group>
                </div>
            </editor-menu-bar>

            <b-alert show variant="secondary">
                <p>Der E-Mail wird automatisch der folgende Text hinzugefügt, damit Antworten auf die E-Mail im Schulkontaktportal entsprechend „einsortiert“ werden können:</p>
                <blockquote class="pl-3 border-left" v-html="autoAttachedText"></blockquote>
            </b-alert>

            <div v-if="!success">
                <b-button v-b-toggle.collapse-attachments variant="primary">Datei(en) anhängen</b-button>
                <b-collapse id="collapse-attachments" class="mt-2" v-model="showAttachmentsSection">
                    <div v-for="attachment in attachments" v-bind:key="attachment.id">
                        <div class="d-flex mb-3">
                            <div
                                    class="flex-grow-1 mr-2"
                            >
                                <b-form-file
                                        v-model="attachment.file"
                                        placeholder="Datei auswählen…"
                                        drop-placeholder="Datei hier ablegen…"
                                        browse-text="Auswählen"
                                        accept=".jpeg, .jpg, .png, .bmp, .pdf, .xls, .doc, .ppt, .xlsx, .docx, .pptx, .mp4, .zip"
                                ></b-form-file>
                            </div>
                            <div
                                    class="flex-shrink-0"
                            >
                                <b-button
                                        variant="outline-danger"
                                        @click="removeAttachment(attachment.id)"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <div
                    v-if="success"
                    v-html="editor.getHTML()"
                    class="content-preview"
            >
            </div>

            <div slot="modal-footer">
                <b-button
                        variant="secondary"
                        v-if="!success"
                        @click="closeModal"
                        v-b-tooltip.hover
                        title="Der Inhalt wird automatisch in deinem Browser zwischengespeichert – also keine Sorge: es kann nichts verloren gehen."
                        :disabled="isSubmitting"
                >
                    Abbrechen*
                </b-button>
                <b-button
                        v-if="!success"
                        variant="primary"
                        @click="onSubmit"
                        :disabled="!isValid || isSubmitting"
                >
                    {{ isSubmitting ? "E-Mail wird versendet…" : "E-Mail versenden" }}
                </b-button>
                <b-button
                        v-if="success"
                        variant="secondary"
                        @click="closeModal"
                >
                    Schließen
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import applyConverters from 'axios-case-converter';
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        History,
        Bold,
        Italic,
        Underline,
        Image,
        HardBreak,
        Blockquote,
        Link,
    } from 'tiptap-extensions'

    export default {
        props: {
            buttonText: {
                type: String,
                default: 'Neue E-Mail schreiben',
            },
            schoolExchangeId: Number,
            bodyPlaceholder: String,
            subjectPlaceholder: String,
            toPlaceholder: String,
            autoAttachedText: String,
            customLocalStorageKey: {
                type: String,
                required: false,
            },
            replyToIncomingMailId: {
                type: Number,
                required: false,
            },
        },
        data: function () {
            return {
                isSubmitting: false,
                showAttachmentsSection: false,
                attachments: [
                    {
                        id: 0,
                        file: null,
                    },
                ],
                modalShow: false,
                axiosClient: null,
                editor: null,
                validationErrors: [],
                errorOnSubmit: false,
                success: false,
                localStorageKey: `drafts.schoolExchangeOutgoingMails.${this.schoolExchangeId}.${this.customLocalStorageKey ? this.customLocalStorageKey : 'new'}`,
                subject: localStorage[this.localStorageSingleKey('subject')] || this.subjectPlaceholder,
                to: localStorage[this.localStorageSingleKey('to')] || this.toPlaceholder,
            };
        },
        mounted: function() {
            this.initializeAxiosClient();
            this.initializeEditor();
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        computed: {
            isValid: function () {
                if (this.editor && this.stripHtml(this.editor.getHTML()).trim() !== "") {
                    return true;
                }
                return false;
            },
        },
        watch: {
            subject: function() {
                if (this.subject && this.subject.trim() !== "") {
                    localStorage[this.localStorageSingleKey('subject')] = this.subject;
                    return;
                }
                localStorage.removeItem(this.localStorageSingleKey('subject'));
            },
            to: function() {
                if (this.to && this.to.trim() !== "") {
                    localStorage[this.localStorageSingleKey('to')] = this.to;
                    return;
                }
                localStorage.removeItem(this.localStorageSingleKey('to'));
            },
            attachments: {
                handler: function() {
                    const validAttachments = this.attachments.filter(attachment => attachment.file !== null);
                    if (validAttachments.length === this.attachments.length - 1) {
                        return;
                    }
                    this.attachments = validAttachments.concat([{
                        file: null,
                        id: Math.max(...validAttachments.map(attachment => attachment.id)) + 1,
                    }]);
                },
                deep: true,
            },
        },
        methods: {
            localStorageSingleKey: function (type) {
                return `${this.localStorageKey}.${type}`
            },
            stripHtml: function (html) {
                // Taken from https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
                const temporalDivElement = document.createElement("div");
                temporalDivElement.innerHTML = html;
                return temporalDivElement.textContent || temporalDivElement.innerText || "";
            },
            closeModal: function () {
                this.modalShow = false;
                this.validationErrors = [];
                this.errorOnSubmit = false;
                this.success = false;
                this.isSubmitting = false;
                this.editor.destroy();
                this.initializeEditor();
                this.showAttachmentsSection = false;
                this.attachments = [{
                    id: 0,
                    file: null,
                }];
                this.subject = localStorage[this.localStorageSingleKey('subject')] || this.subjectPlaceholder;
                this.to = localStorage[this.localStorageSingleKey('to')] || this.toPlaceholder;
            },
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            initializeEditor: function () {
                this.editor = new Editor({
                    content: localStorage[this.localStorageSingleKey('body')] || this.bodyPlaceholder,
                    extensions: [
                        new History(),
                        new Bold(),
                        new Italic(),
                        new Underline(),
                        new Image(),
                        new HardBreak(),
                        new Blockquote(),
                        new Link(),
                    ],
                    onUpdate: ({ getHTML }) => {
                        const htmlContent = getHTML();
                        if (htmlContent === "<p></p>") {
                            localStorage.removeItem(this.localStorageSingleKey('body'));
                            return
                        }
                        localStorage[this.localStorageSingleKey('body')] = htmlContent;
                    },
                });
            },
            submit: async function() {
                if (!this.isValid) {
                    return
                }

                const validAttachments = this.attachments.filter(attachment => attachment.file !== null);
                const uploadedFileIds = [];

                for (const attachment of validAttachments) {
                    try {
                        const formData = new FormData();
                        formData.append('name', attachment.file.name);
                        formData.append('file', attachment.file);
                        const response = await this.axiosClient.post('/api/v1/file',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            },
                        );
                        const fileId = response.data.id;
                        uploadedFileIds.push(fileId);
                    } catch (error) {
                        if (error.response && error.response.status === 422) {
                            this.validationErrors = Object.values(error.response.data.errors).flat();
                            return
                        }
                        this.errorOnSubmit = true;
                        return
                    }
                }

                const emailBodyHtml = this.editor.getHTML();
                let emailSubject = this.subjectPlaceholder;
                if (this.subject && this.subject.trim() !== "") {
                    emailSubject = this.subject;
                }
                let emailTo = this.toPlaceholder;
                if (this.to && this.to.trim() !== "") {
                    emailTo = this.to;
                }

                try {
                    const response = await this.axiosClient.post(`/api/v1/schoolExchange/${this.schoolExchangeId}/outgoingMail`, {
                        schoolExchangeId: this.schoolExchangeId,
                        body: emailBodyHtml,
                        subject: emailSubject,
                        to: emailTo,
                        replyToIncomingMailId: this.replyToIncomingMailId || null,
                        attachments: uploadedFileIds,
                    });
                    const emailId = response.data.id;
                    console.log(emailId);
                } catch (error) {
                    if (error.response && error.response.status === 422) {
                        this.validationErrors = Object.values(error.response.data.errors).flat();
                        return
                    }
                    this.errorOnSubmit = true;
                    return
                }

                this.success = true;
                localStorage.removeItem(this.localStorageSingleKey('body'));
                localStorage.removeItem(this.localStorageSingleKey('subject'));
                localStorage.removeItem(this.localStorageSingleKey('to'));
            },
            onSubmit: async function (event) {
                event.preventDefault();

                this.isSubmitting = true;
                await this.submit();
                this.isSubmitting = false;
            },
            removeAttachment: function(id) {
                const index = this.attachments.map(attachment => attachment.id).indexOf(id);
                this.attachments.splice(index, 1);
            }
        },
        name: "school-exchange-compose-mail-component",
        components: {
            EditorContent,
            EditorMenuBar,
        }
    }

</script>

<style scoped>

    .editor__content {
        height: auto;
        max-height: 40vh;
        padding-top: 1rem;
        overflow-y: scroll;
    }

    .editor__content >>> img,
    .content-preview >>> img {
        width: 400px;
        height: auto;
    }

    .editor__content >>> blockquote,
    .content-preview >>> blockquote {
        border-left: 3px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.8);
        padding-left: .8rem;
    }

    .editor__menu-bar {
        margin-top: -1px;
    }

</style>
