<template>
    <div class="md-overflow-y-scroll md-max-height-20">
        <div
            v-if="!loading && learningsEntries.length === 0"
        >
            <li class="list-group-item list-group-item-no-vertical-border text-center w-100">
                <em class="text-muted" v-html="this.noEntriesYetPlaceholder"></em>
            </li>
        </div>
        <div
            v-if="loading"
        >
            <li class="list-group-item list-group-item-no-vertical-border text-center w-100">
                <em class="text-muted">Wird geladen …</em>
            </li>
        </div>
        <school-learnings-entry-component
                v-else
                v-for="learningsEntry in learningsEntries"
                :key="learningsEntry.id"
                :learningsEntry="learningsEntry"
                :destroy-learnings-entry-api-url="destroyLearningsEntryApiUrl"
        >
        </school-learnings-entry-component>
    </div>
</template>

<script>

    import Event from '../event.js';
    import applyConverters from 'axios-case-converter';

    import SchoolLearningsEntryComponent from "./SchoolLearningsEntryComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            schoolId: String,
            noEntriesYetPlaceholder: String,
            destroyLearningsEntryApiUrl: String,
        },
        data() {
            return {
                loading: true,
                axiosClient: null,
                learningsEntries: [],
            }
        },
        mounted() {
            this.initializeAxiosClient();
            this.loadLearningsEntries();
            Event.$on('added_learnings_entry', (learningsEntry) => {
                this.learningsEntries.unshift(learningsEntry);
            });
            Event.$on('removed_learnings_entry', (learningsEntry) => {
                this.learningsEntries.splice(this.learningsEntries.findIndex((existingLearningsEntry) => {
                    return existingLearningsEntry.id === learningsEntry.id;
                }), 1);
            });
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            loadLearningsEntries: function() {
                this.axiosClient.get(`/api/v1/school/${this.schoolId}/learningsEntry`).then(response => {
                    this.learningsEntries = response.data;
                    this.loading = false;
                });
            },
        },
        name: "school-learnings-entries-component",
        components: {
            SchoolLearningsEntryComponent,
        },
    }

</script>

<style scoped>

</style>
