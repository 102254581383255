<template>
    <div class="md-overflow-y-scroll md-max-height-20">
        <div
                v-if="schoolExchangeChatMessages.length === 0"
        >
            <li class="list-group-item list-group-item-no-vertical-border">
                <em class="text-muted" v-html="this.noMessagesYetPlaceholder"></em>
            </li>
        </div>
        <school-exchange-chat-message-component
                v-else
                v-for="schoolExchangeChatMessage in schoolExchangeChatMessages"
                :key="schoolExchangeChatMessage.id"
                :schoolExchangeChatMessage="schoolExchangeChatMessage">
        >
        </school-exchange-chat-message-component>
    </div>
</template>

<script>

    import Event from '../event.js';
    import SchoolExchangeChatMessageComponent from "./SchoolExchangeChatMessageComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            noMessagesYetPlaceholder: String,
        },
        data() {
            return {
                schoolExchangeChatMessages: [],
            }
        },
        mounted() {
            axios.get(`/api/v1/schoolExchange/${this.schoolExchangeId}/chatMessage`).then(response => {
                this.schoolExchangeChatMessages = response.data;
            });
            Event.$on('added_message', (message) => {
                this.schoolExchangeChatMessages.unshift(message);
            });
        },
        name: "school-exchange-chat-messages-component",
        components: {
            SchoolExchangeChatMessageComponent,
        },
    }
</script>

<style scoped>

</style>
