<template>
    <ol class="list-group ml-n3 mr-n3 mb-2">
        <school-exchange-chat-form-component
                :school-exchange-id="this.schoolExchangeId"
        ></school-exchange-chat-form-component>
        <school-exchange-chat-messages-component
                :school-exchange-id="this.schoolExchangeId"
                :no-messages-yet-placeholder="this.noMessagesYetPlaceholder"
        ></school-exchange-chat-messages-component>
    </ol>
</template>

<script>

    import Event from '../event';

    import SchoolExchangeChatFormComponent from "./SchoolExchangeChatFormComponent";
    import SchoolExchangeChatMessagesComponent from "./SchoolExchangeChatMessagesComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            noMessagesYetPlaceholder: String,
        },
        mounted: function () {
            Echo.join(`App.SchoolExchangeChat.${this.schoolExchangeId}`)
                .listen('SchoolExchangeChatMessageCreated', (data) => {
                    Event.$emit('added_message', data.school_exchange_chat_message);
                });
        },
        name: "school-exchange-chat-component",
        components: {
            SchoolExchangeChatFormComponent,
            SchoolExchangeChatMessagesComponent,
        },
    }
</script>

<style scoped>

</style>
