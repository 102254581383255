<template>
    <ol class="list-group list-group-flush ml-n3 mr-n3 mb-2">
        <school-learnings-form-component
            :school-exchange-id="this.schoolExchangeId"
            :school-id="this.schoolId"
            :create-learnings-entry-api-url="this.createLearningsEntryApiUrl"
        ></school-learnings-form-component>
        <school-learnings-entries-component
            :school-exchange-id="this.schoolExchangeId"
            :school-id="this.schoolId"
            :no-entries-yet-placeholder="this.noEntriesYetPlaceholder"
            :destroy-learnings-entry-api-url="this.destroyLearningsEntryApiUrl"
        ></school-learnings-entries-component>
    </ol>
</template>

<script>

    import SchoolLearningsFormComponent from "./SchoolLearningsFormComponent";
    import SchoolLearningsEntriesComponent from "./SchoolLearningsEntriesComponent";

    export default {
        props: {
            schoolExchangeId: Number,
            schoolId: String,
            noEntriesYetPlaceholder: String,
            createLearningsEntryApiUrl: String,
            destroyLearningsEntryApiUrl: String,
        },
        name: "school-learnings-component",
        components: {
            SchoolLearningsFormComponent,
            SchoolLearningsEntriesComponent,
        },
    }

</script>

<style scoped>

</style>
