<template>
    <div>
        <b-button
                variant="primary"
                @click="modalShow = !modalShow"
        >
            Lernreise-Gruppe auswählen/erstellen
        </b-button>

        <!-- Modal Component -->
        <b-modal
                id="modal-select-or-create-group"
                v-model="modalShow"
                size="lg"
                scrollable
                modal-class="modal-fullscreen-bottom-footer"
                title="Lernreise-Gruppe auswählen/erstellen"
        >
            <b-form
                    @submit.prevent="onSubmit"
            >

                <b-form-group
                        id="searchExistingGroupInputGroup"
                        label="Existierende Lernreise-Gruppe suchen:"
                        description="Du kannst eine neue Lernreise-Gruppe anlegen, indem du ihren Spitznamen eingibst."
                        label-for="searchExistingGroupInput"
                >
                    <vue-bootstrap-typeahead
                            id="searchExistingGroupInput"
                            placeholder="Spitzname oder Uni der Lernreise-Gruppe"
                            v-model="groupSearch"
                            :data="groupsIncludingNewGroupEntry"
                            :serializer="serializeGroup"
                            @hit="groupNameTypeaheadHit"
                            :min-matching-chars="0"
                            :max-matches="255"
                            :query-transformer="queryTransformer"
                            :disabled="isSubmitting"
                    >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span v-if="data.id === -1">
                                <strong>{{ data.name }}</strong><br/>
                                <small>Neue Lernreise-Gruppe anlegen</small>
                            </span>
                            <span v-else>
                                <span v-html="htmlText"></span>
                                <span class="d-block text-muted small">
                                    <span class="pr-2" v-if="data.university">{{ data.university.name }}</span>
                                    <span v-if="data.groupVisitType"><em>Reisezeit: {{ data.groupVisitType.name }}</em></span>
                                </span>
                            </span>
                        </template>
                    </vue-bootstrap-typeahead>
                </b-form-group>

                <b-card
                        bg-variant="light"
                        v-if="isAddingNewGroup"
                >
                    <b-form-group
                            id="newGroupNameInputGroup"
                            label-cols-sm="4"
                            label="Spitzname:*"
                            label-align-sm="right"
                            label-for="newGroupNameInput"
                    >
                        <b-form-input
                                id="newGroupNameInput"
                                type="text"
                                required
                                :disabled="!isAddingNewGroup || isSubmitting"
                                v-model="newGroup.name"
                        />
                    </b-form-group>

                    <b-form-group
                        id="newGroupGroupVisitTypeInputGroup"
                        label-cols-sm="4"
                        label="Reisezeit:*"
                        label-align-sm="right"
                        label-for="newGroupGroupVisitTypeInput"
                    >
                        <b-form-select
                            id="newGroupGroupVisitTypeInput"
                            v-model="newGroup.groupVisitTypeId"
                            :options="this.groupVisitTypes.map(visitType => ({ value: visitType.id,text: visitType.name }))"
                            :disabled="!isAddingNewGroup || isSubmitting"
                        >
                            <template slot="first">
                                <option :value="undefined" disabled>(bitte auswählen)</option>
                            </template>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="newGroupUniversityInputGroup"
                        label-cols-sm="4"
                        label="Universität/Hochschule:*"
                        description="Du kannst eine neue Universität/Hochschule anlegen, indem du ihren Namen eingibst."
                        label-align-sm="right"
                        label-for="newGroupUniversityInput"
                    >
                        <vue-bootstrap-typeahead
                            id="newGroupUniversityInput"
                            placeholder="bspw. Humboldt-Universität Berlin"
                            v-model="universitySearch"
                            :data="universitiesIncludingNewEntry"
                            :serializer="item => `${item.name}`"
                            @hit="universityTypeaheadHit"
                            :min-matching-chars="0"
                            :query-transformer="queryTransformer"
                            :disabled="!isAddingNewGroup || isSubmitting"
                        >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <span v-if="data.id === -1">
                                <strong>{{ data.name }}</strong><br/>
                                <small>Neue Universität/Hochschule anlegen</small>
                            </span>
                                <span v-else v-html="htmlText"></span>
                            </template>
                        </vue-bootstrap-typeahead>
                    </b-form-group>

                    <b-form-group
                        id="newGroupApproximateNumberOfStudentsInputGroup"
                        label-cols-sm="4"
                        label="Ungefähre Zahl der Studierenden:"
                        description="Diese Angabe ist optional."
                        label-align-sm="right"
                        label-for="newGroupApproximateNumberOfStudentsInput"
                    >
                        <b-input-group
                            append="± 1"
                        >
                            <b-form-input
                                id="newGroupApproximateNumberOfStudentsInput"
                                v-model="newGroup.approximateNumberOfStudents"
                                type="number"
                                :disabled="!isAddingNewGroup || isSubmitting"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-card>
            </b-form>

            <div slot="modal-footer">
                <b-button
                        variant="secondary"
                        @click="modalShow = false"
                        :disabled="isSubmitting"
                >
                    Abbrechen
                </b-button>
                <b-button
                        variant="primary"
                        @click="onSubmit"
                        :disabled="!formIsCompleted || isSubmitting"
                >
                    {{ this.isSubmitting ? 'Bitte warten …' : (this.isAddingNewGroup ? 'Lernreise-Gruppe anlegen' : 'Lernreise-Gruppe beitreten') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import applyConverters from 'axios-case-converter';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    const _ = require("lodash");

    export default {
        props: {
            groupsApiUrl: String,
            groupVisitTypesApiUrl: String,
            universitiesApiUrl: String,
            groupMemberApiUrl: String,
        },
        data: function () {
            return {
                axiosClient: null,
                modalShow: false,
                groups: [],
                groupSearch: '',
                newGroup: {},
                selectedGroupId: null,
                universities: [],
                universitySearch: '',
                groupVisitTypes: [],
                validationErrors: [],
                isSubmitting: false,
            }
        },
        computed: {
            groupsIncludingNewGroupEntry: function () {
                if (
                    this.selectedGroupId
                    && this.selectedGroupId !== -1
                    && this.groups.find(group => group.id === this.selectedGroupId)
                    && this.groupSearch === this.serializeGroup(this.groups.find(group => group.id === this.selectedGroupId))
                ) {
                    return this.groups;
                }
                if (this.groupSearch === "" || this.groupSearch === undefined) {
                    return this.groups;
                }
                return [...this.groups, {
                    id: -1,
                    name: this.groupSearch,
                }];
            },
            universitiesIncludingNewEntry: function () {
                if (this.universities.some(university => {
                    return university.name === this.universitySearch.trim()
                })) {
                    return this.universities;
                }
                if (this.universitySearch === "" || this.universitySearch === undefined) {
                    return this.universities;
                }
                return [...this.universities, {
                    id: -1,
                    name: this.universitySearch,
                }];
            },
            isAddingNewGroup: function () {
                return this.selectedGroupId === -1;
            },
            isAddingNewUniversity: function () {
                return this.newGroup.university && this.newGroup.university.id === -1;
            },
            formIsCompleted: function () {
                if (this.isAddingNewGroup) {
                    return (
                        this.newGroup
                        && this.newGroup.name !== undefined
                        && this.newGroup.name !== ""
                        && this.newGroup.university !== undefined
                        && this.newGroup.university.id !== undefined
                        && this.newGroup.groupVisitTypeId !== undefined
                    );
                } else {
                    return !!this.selectedGroupId;
                }
            }
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            loadGroups: function () {
                this.axiosClient.get(this.groupsApiUrl)
                    .then(response => {
                        this.groups = response.data;
                    });
            },
            loadGroupVisitTypes: function () {
                this.axiosClient.get(this.groupVisitTypesApiUrl)
                    .then(response => {
                        this.groupVisitTypes = response.data;
                    });
            },
            loadUniversities: function () {
                this.axiosClient.get(this.universitiesApiUrl)
                    .then(response => {
                        this.universities = response.data;
                    });
            },
            queryTransformer: function(queryString) {
                return queryString.replace(/[ -]/g, '[ -]')
            },
            serializeGroup: function(item) {
                return item.name;
            },
            groupNameTypeaheadHit: function (data) {
                const id = data.id;
                if (id === -1) {
                    this.newGroup.name = data.name;
                } else {
                    this.newGroup = {};
                }
                this.selectedGroupId = id;
            },
            universityTypeaheadHit: function (data) {
                this.$set(this.newGroup, 'university', {
                    id: data.id,
                    name: data.name,
                });
            },
            onSubmit: async function (event) {

                event.preventDefault();

                if (!(this.formIsCompleted)) {
                    return;
                }

                this.isSubmitting = true;

                let groupId;

                if (this.isAddingNewGroup) {

                    let universityId = undefined;

                    if (this.isAddingNewUniversity) {
                        try {
                            const response = await this.axiosClient.post(this.universitiesApiUrl, {
                                name: this.newGroup.university.name,
                            });
                            universityId = response.data.id;
                        } catch (error) {
                            if (error.response.status === 422) {
                                this.validationErrors = error.response.data.errors;
                            }
                            this.isSubmitting = false;
                            return;
                        }

                    } else if (this.newGroup.university && this.newGroup.university.id) {
                        universityId = this.newGroup.university.id;
                    }

                    try {
                        const response = await this.axiosClient.post(this.groupsApiUrl, {
                            name: this.newGroup.name,
                            groupVisitTypeId: this.newGroup.groupVisitTypeId,
                            universityId: universityId,
                            approximateNumberOfStudents: this.newGroup.approximateNumberOfStudents,
                        });
                        groupId = response.data.id;
                    } catch (error) {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                        this.isSubmitting = false;
                        return;
                    }

                } else {
                    groupId = this.selectedGroupId;
                }

                try {
                    await this.axiosClient.post(this.groupMemberApiUrl, {
                        groupId: groupId
                    });
                } catch (error) {
                    if (error.response.status === 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.isSubmitting = false;
                    return;
                }

                this.isSubmitting = false;
                window.location.href = '/';
            }
        },
        name: "create-group-component",
        components: {
            VueBootstrapTypeahead,
        },
        mounted: function () {
            this.initializeAxiosClient();
            this.loadGroups();
            this.loadGroupVisitTypes();
            this.loadUniversities();
        },
    }
</script>

<style scoped>

</style>
