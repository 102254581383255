<template>
    <li class="list-group-item list-group-item-no-vertical-border">
        <div class="d-flex w-100 justify-content-center">
            <b-button
                variant="primary"
                @click="modalShow = !modalShow"
            >
                Neuen Eintrag erstellen
            </b-button>

            <!-- Modal Component -->
            <b-modal
                id="modal-create-contact-protocol-entry"
                v-model="modalShow"
                size="lg"
                scrollable
                modal-class="modal-fullscreen-bottom-footer"
                title="Neuen Kontaktprotokoll-Eintrag erstellen"
            >
                <b-form
                    @submit.prevent="onSubmit"
                >
                    <b-form-group
                        id="title-input-group"
                        label-cols-sm="2"
                        label="Titel:*"
                        label-for="title-input"
                        label-align-sm="right"
                    >
                        <b-form-input
                            id="title-input"
                            name="title-input"
                            type="text"
                            placeholder="bspw. „Mit Schulleiter*in telefoniert“"
                            required
                            autofocus
                            v-model="title"
                            v-validate.immediate="{
                                required: true
                            }"
                            :state="validateState('title-input')"
                            aria-describedby="title-input-live-feedback"
                            :disabled="isSubmitting"
                        ></b-form-input>

                        <b-form-invalid-feedback id="title-input-live-feedback">
                            Bitte gib den Titel des Kontaktprotokoll-Eintrags an.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id="body-input-group"
                        label-cols-sm="2"
                        label="Beschreibung*"
                        label-for="body-input"
                        label-align-sm="right"
                    >
                        <b-form-textarea
                            id="body-input"
                            name="body-input"
                            v-model="body"
                            rows="3"
                            max-rows="6"
                            v-validate.immediate="{
                                required: false,
                                max: 65535
                            }"
                            :state="validateState('body-input')"
                            aria-describedby="body-input-live-feedback"
                            :disabled="isSubmitting"
                        ></b-form-textarea>

                        <b-form-invalid-feedback id="body-input-live-feedback">
                            Die Beschreibung darf maximal 65535 Zeichen enthalten.
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-form>

                <div slot="modal-footer">
                    <b-button
                        variant="secondary"
                        @click="modalShow = false"
                        :disabled="isSubmitting"
                    >
                        Abbrechen
                    </b-button>
                    <b-button
                        variant="primary"
                        @click="onSubmit"
                        :disabled="veeErrors.any() || isSubmitting"
                    >
                        {{ this.isSubmitting ? 'Bitte warten …' : 'Eintrag anlegen' }}
                    </b-button>
                </div>
            </b-modal>
        </div>
    </li>
</template>

<script>

    import Event from '../event.js';
    import applyConverters from 'axios-case-converter';

    export default {
        data() {
            return {
                axiosClient: null,
                modalShow: false,
                isSubmitting: false,
                validationErrors: [],
                title: null,
                body: null,
            }
        },
        props: {
            schoolExchangeId: Number,
            createProtocolEntryApiUrl: String,
        },
        methods: {
            initializeAxiosClient: function () {
                this.axiosClient = applyConverters(axios);
            },
            onSubmit: async function(event) {
                event.preventDefault();
                await this.$validator.validateAll().then(async (result) => {
                    if (!result) {
                        return;
                    }
                    this.isSubmitting = true;

                    let newlyAddedProtocolEntry = undefined;

                    try {
                        const response = await this.axiosClient.post(this.createProtocolEntryApiUrl, {
                            schoolExchangeId: this.schoolExchangeId,
                            title: this.title,
                            body: this.body,
                        });
                        newlyAddedProtocolEntry = response.data;

                        // Reset the form data on successful submit
                        this.title = null;
                        this.body = null;
                    } catch (error) {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                        this.isSubmitting = false;
                        return;
                    }

                    this.isSubmitting = false;
                    this.modalShow = false;

                    Event.$emit('added_contact_protocol_entry', newlyAddedProtocolEntry);
                });
            },
            validateState(ref) {
                if (
                    this.veeFields[ref] &&
                    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
                ) {
                    return !this.veeErrors.has(ref);
                }
                return null;
            },
        },
        mounted: function() {
            this.initializeAxiosClient();
        },
        name: "school-exchange-contact-protocol-form-component"
    }

</script>

<style scoped>

</style>
